import { Dispatch } from 'react';
import { AxiosError, AxiosResponse } from 'axios';

import { api, ROUTES } from '../../../services/api';
import { BOT_ACTIONS } from './index';
import ERRORS from '../../../constants/errors.constants';
import { IndexBotsResponse } from '../../../typings';
import { ReduxReducers } from '../../reducers';

const fetchBots = (
    page: Number,
    perPage: number,
    name?: string,
    status?: number,
    category?: string,
    callback?: (err: any, message?: string) => void,
) => {
    return async (dispatch: Dispatch<any>, getState: () => ReduxReducers) => {
        try {
            const { token } = getState().auth;

            dispatch({
                type: BOT_ACTIONS.FETCH_BOTS,
            });

            const response = await api.get<IndexBotsResponse, AxiosResponse<IndexBotsResponse>>(
                `${ROUTES.BOT.INDEX}?page=${page}&perPage=${perPage}${category && `&filterByCategory=${category}`}${name && `&filterByBotName=${name}`}${status !== null && status !== -2 ? `&filterByBotStatus=${status}` : ''}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

            if (response.data?.data && response?.data?.errors?.length === 0) {
                const { data: bots, totalPages, report } = response?.data?.data;

                dispatch({
                    type: BOT_ACTIONS.FETCH_BOTS_SUCCESS,
                    payload: {
                        bots,
                        totalPages,
                        report,
                    },
                });
                
                return callback && callback(false, 'Bot carregados com sucesso!');
            }

            const ERROR_CODE = response.data?.errors[0];

            dispatch({
                type: BOT_ACTIONS.FETCH_BOTS_FAILED,
            });
            
            return callback && callback(true, ERRORS[ERROR_CODE])
        } catch (error: any) {

            if (error.response) {
                const ERROR_CODE = (error as AxiosError<IndexBotsResponse>).response?.data?.errors[0];
                dispatch({
                    type: BOT_ACTIONS.FETCH_BOTS_FAILED,
                });
                return callback && callback(true, ERRORS[ERROR_CODE!]);
            }

            dispatch({
                type: BOT_ACTIONS.FETCH_BOTS_FAILED,
            });
            return callback && callback(true, String(error));
        }
    }
}

export default fetchBots;