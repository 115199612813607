import {
    Stack,
    Container,
} from '@mui/material';
import { 
    Page,
} from '../../../components';
import { CategoriesList } from '../../../components/lists/category-list/category-list';
import HeaderBreadcrumbs from '../../../components/header-breadcrumbs';

interface Props {}

const CategoriesScreen: React.FC<Props> = () => {
    return (
        <>
            <Page title='PubZap - Categorias'>
                <Container maxWidth={false}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <HeaderBreadcrumbs
                            heading="Categorias"
                            links={[
                                {
                                    name: 'Dashboard',
                                    href: '/categories'
                                },
                                { name: 'Categorias', href: '/categories' },
                            ]}
                            activeLast={false}
                        />
                    </Stack>
                    <CategoriesList />
                </Container>
            </Page>
        </>
    )
}

export default CategoriesScreen;