import React, { useState, useEffect } from 'react';
import { When } from 'react-if';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { toast } from 'react-toastify';

import Loading from '../../loading';
import NoData from '../../no-data';
import { AreYouSureModal } from '../..';
import { Funnel } from '../../../typings';
import { ReduxReducers } from '../../../store/reducers';
import { toggleModal } from '../../../store/actions/funnels';
import { PaginatorFactory } from '../../../factory';
import { FunnelState } from '../../../store/reducers/funnels';
import { MoreOptionsList } from '../more-options';
import { CATEGORY_COLUMUNS } from '../../../builders/category.builders';
import { api } from '../../../services/api';
import { AuthState } from '../../../store/reducers/auth';
import { CategoryForm } from '../../forms/category.form';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';

export const CategoriesList: React.FC = () => {
    const dispatch = useDispatch();

    const [selectedCategory, setSelectedCategory] = useState({} as any);

    const { token } = useSelector<ReduxReducers, AuthState>(s => s.auth);

    const [categories, setCategories] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState('');

    const handleGetCategories = async () => {
        try {
            setLoading(true);

            const result = await api.get('/Categories', {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
            });

            setCategories(result.data?.data);
        } catch (e: any) {
            console.log('Error get categories:', e);
        }

        setLoading(false);
        setModal('');
    }

    const handleDeleteCategory = async () => {
        try {
            const result = await api.delete('/Categories/' + selectedCategory.id, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
            });

            if (result.status === 200) {
                toast.success('Categoria deletada com sucesso!');
                handleGetCategories();
            }
        } catch (e: any) {
            console.log('Error delete category:', e);
            toast.success('Erro ao deletar categoria!');
        }

        setModal('');
    }

    useEffect(() => {
        handleGetCategories();
    }, []);

    return (
        <>
            <Card>
                <Button
                    variant="contained"
                    component={RouterLink}
                    to="#"
                    startIcon={<Icon icon={plusFill} />}
                    onClick={() => setModal('create')}
                    style={{
                        marginTop: 10,
                        marginLeft: 10,
                        marginBottom: 10,
                    }}
                >
                    Adicionar Categoria
                </Button>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow> 
                                {CATEGORY_COLUMUNS?.map((column) => (
                                    <TableCell>{column?.headerName}</TableCell>
                                ))}
                                <TableCell>
                                    Ações
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <When condition={!loading}>
                            <TableBody>
                                {categories?.map((category) => (
                                    <TableRow key={category?.id}>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {category?.id}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {category?.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {category?.slug}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {dayjs(category?.created_at).format('DD/MM/YYYY HH:MM')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {dayjs(category?.updated_at).format('DD/MM/YYYY HH:MM')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <MoreOptionsList
                                                options={[
                                                    {
                                                        icon: <DeleteIcon fontSize="small" />,
                                                        label: 'Excluir',
                                                        onClick: () => {
                                                            setSelectedCategory(category);
                                                            // dispatch(toggleModal('delete', true));
                                                            setModal('delete');
                                                        },
                                                    },
                                                ]}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </When>
                    </Table>

                    <When condition={loading}>
                        <Loading />
                    </When>

                    <When condition={loading !== true && categories?.length < 1}>
                        <NoData 
                            buttonText='Adicionar'
                            onClick={() => dispatch(toggleModal('create', true))}
                            title={'Nenhuma categoria foi encontrada.'}
                            description={'Aparentemente, não há nenhuma categoria cadastrada.'}
                        />
                    </When>
                </TableContainer>

                {/* <TablePagination
                    rowsPerPageOptions={[50, 100, 150]}
                    component="div"
                    count={totalItems}
                    rowsPerPage={pagination?.perPage}
                    page={pagination?.page}
                    onPageChange={(e, p) => setPagination(pag => ({ ...pag, page: p }))}
                    onRowsPerPageChange={(e) => setPagination(pag => ({ ...pag, perPage: parseInt(e.target.value, 10) }))}
                /> */}
            </Card>

            <CategoryForm
                isFromEdit={false}
                visible={modal === 'create'}
                onHide={() => {
                    setSelectedCategory({} as any);
                    handleGetCategories();
                    setModal('');
                }}
                funnel={selectedCategory}
            />

            <AreYouSureModal
                title="Tem certeza?"
                description="Tem certeza que deseja excluir essa categoria? Ela será excluída permanentemente."
                loading={loading}
                visible={modal === 'delete'}
                onHide={() => {
                    setModal('');
                }}
                onConfirm={() => {
                    handleDeleteCategory();
                }}
            />
        </>
    )
}