import React, { useState } from 'react';
import { 
    Dialog,
    useTheme,
    useMediaQuery,
    DialogContent,
    Box,
    Typography,
    DialogActions,
    Button,
    Stack,
    TextField,
} from '@mui/material';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useFormik, Form, FormikProvider } from 'formik';

import { api } from '../../services/api';
import { ReduxReducers } from '../../store/reducers';
import { Funnel } from '../../typings';
import { AuthState } from '../../store/reducers/auth';

interface Props {
    visible: boolean,
    isFromEdit: boolean,
    funnel: Funnel,
    onHide(): void,
};

export const CategoryForm: React.FC<Props> = ({
    visible,
    onHide,
}) => {
    const { token } = useSelector<ReduxReducers, AuthState>(s => s.auth);
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));    

    const FORM_SCHEMA = Yup.object().shape({
        name: Yup
            .string()
            .required('Informe o nome da categoria'),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: FORM_SCHEMA,
        onSubmit: async () => {
            await saveCategory();
        },
    });

    const { 
        errors,
        touched,
        values,
        handleSubmit,
        getFieldProps,
    } = formik;

    const saveCategory = async () => {
        try {
            setLoading(true);

            let success = false;

            const result = (await api.post('/Categories', {
                name: values?.name,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            }
            )).data

            if (result?.data?.id) {
                success = true;
            }

            if (success) {
                toast(
                    'Categoria salva com sucesso!',
                    {
                        autoClose: 1500,
                        type: 'success',
                    }
                );

                onHide();
            } else {
                toast(
                    'Error ao salvar a categoria!',
                    {
                        autoClose: 1500,
                        type: 'error',
                    }
                );
            }
            
            onHide();
        } catch (e: any) {
            toast(
                'Error ao salvar a categoria!',
                {
                    autoClose: 1500,
                    type: 'error',
                }
            );
            console.log(e);
        }

        setLoading(false);
        formik.resetForm();
    }

    return (
        <Dialog
            open={visible}
            onClose={() => {
                formik.resetForm();
                onHide();
            }}
            fullScreen={smallScreen}
            fullWidth
            maxWidth='md'
            scroll={"paper"}
        >
            <Box sx={{ borderBottom: 1, borderColor: 'divider', background: '#FAFAFA' }}>
                <Typography sx={{ mb: 2, mt: 2}} textAlign={'center'} fontSize={18} fontWeight={400} color={'#131340'}>
                    {'Cadastrar uma nova categoria'}
                </Typography>
            </Box>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <DialogContent style={{ width: '100%' }}>
                        <Stack  justifyContent="space-between">
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                type="text"
                                label="Nome da categoria"
                                {...getFieldProps('name')}
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ borderTop: 1, borderColor: 'divider', background: '#FAFAFA' }}>
                        <Button onClick={onHide} color='inherit' disabled={loading}>
                            Cancelar
                        </Button>
                        <LoadingButton autoFocus loading={loading} variant='contained' type="submit">
                            Confirmar
                        </LoadingButton>
                </DialogActions>
                </Form>
            </FormikProvider>
        </Dialog>
    )
}