import { ReactNode } from 'react';
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { FieldInputProps } from 'formik';
import InputMask from 'react-input-mask';

import { InputForm } from '../../typings';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

interface Props {
    inputs: InputForm[],
    touched: any,
    size?: "medium" | "small",
    values?: any,
    errors: any,
    after?: ReactNode,
    setFieldValue?(id: string, v: any): any,
    getFieldProps(n: string): FieldInputProps<any>, 
};

function access(obj: any, prop: string, defVal?: any) {
    if (typeof prop === 'string') {
        var objectNames = prop.split(".");

        var curObj = obj;
    
        for (var i = 0, len = objectNames.length; i < len; i++) {
            curObj = curObj[objectNames[i]];
            if (typeof curObj === 'undefined') {
                return defVal;
            }
        }
        return curObj;
    }
   
}

const FormBuilder = (props: Props) => {
    const {
        inputs,
        errors,
        values,
        setFieldValue,
        getFieldProps,
        size = 'medium',
    } = props;

    return (
        <>
            {inputs?.map((input, i) => {
                const error = access(errors, input?.id);
                if (input?.type == 'select') {
                    return (
                        <Grid item key={`input-${input?.id}-${input?.type}`} { ...input.grid }>
                            <FormControl fullWidth>
                                <InputLabel>{input?.label}</InputLabel>
                                <Select
                                    label="Responsável"
                                    fullWidth
                                    error={Boolean(error)}
                                    {...getFieldProps(input?.id)}
                                >
                                    <MenuItem value={''}>
                                    </MenuItem>
                                    {input?.options?.map(op => (
                                        <MenuItem key={op?.value} value={op?.value}>{op?.label}</MenuItem>
                                    ))}
                                </Select>
                                {Boolean(error) &&
                                    <FormHelperText color="red" style={{ color: 'red' }}>
                                        {error}
                                    </FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                    )
                }

                if (input?.type === 'date') {
                    return (
                        <Grid item key={`input-${input?.id}-${input?.type}`} { ...input.grid }>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label={input?.label}
                                    onChange={(newValue: any) => {
                                        setFieldValue && setFieldValue(input?.id, newValue);
                                    }}
                                    inputFormat={'dd/MM/yyyy'}
                                    value={values[input?.id] ? values[input?.id] : null}
                                    renderInput={(params: any) => <TextField 
                                        fullWidth
                                        {...params}
                                    />}
                                />
                            </LocalizationProvider>
                        </Grid>
                    )
                }

                return (
                    <Grid item key={`input-${input?.id}-${input?.type}`} { ...input.grid }>
                        {input?.mask ? (
                            <InputMask mask={input?.mask} {...getFieldProps(input.id)}>
                                <TextField 
                                    type={input.type}
                                    size={size}
                                    fullWidth
                                    key={`${i}-${input.label}-${input.type}`}
                                    label={input.label}
                                    placeholder={input.placeholder}
                                    required={input.required}
                                    error={Boolean(error)}
                                    helperText={error}
                                    {...getFieldProps(input.id)}
                                />
                            </InputMask>
                        ) : (
                            <TextField 
                                size={size}
                                fullWidth
                                type={input.type}
                                key={`${i}-${input.label}-${input.type}`}
                                label={input.label}
                                placeholder={input.placeholder}
                                required={input.required}
                                error={Boolean(error)}
                                helperText={error}
                                {...getFieldProps(input.id)}
                            />
                        )}
                    </Grid>
                )
            })}
            {props?.after}
        </>
    )

}

export default FormBuilder;