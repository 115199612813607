import React, { 
    memo,
    useEffect,
    useState,
} from 'react';
import * as Yup from 'yup';
import {
    Stack,
    TextField,
    Dialog,
    DialogContent,
    DialogTitle,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    FormControlLabel,
    Switch,
} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify'
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxReducers } from '../../../store/reducers';
import { BotState } from '../../../store/reducers/bots';
import { createBot, toggleModal, setValue, updateBot } from '../../../store/actions/bots';
import { AuthState } from '../../../store/reducers/auth';
import { api } from '../../../services/api';

const BotForm: React.FC = () => {
    const dispatch = useDispatch();
    const { token } = useSelector<ReduxReducers, AuthState>(s => s.auth);

    const { loading, modal, bot } = useSelector<ReduxReducers, BotState>(s => { 
        console.log(s);
        return s.bots
    });

    const theme = useTheme();

    const IS_FULL_SCREEN = useMediaQuery(theme.breakpoints.down('md'));

    const [categories, setCategories] = useState([] as any);
    const [loadingCategories, setLoadingCategories] = useState(false);

    const BOT_FORM = Yup.object().shape({
        name: Yup
            .string()
            .required('Informe o nome do bot'),
        hourlyLimitSup: Yup
            .number()
            .required('Informe o limite superior de envios por hora'),
        hourlyLimitInf: Yup
            .number()
            .required('Informe o limite inferior de envios por hora'),
        secondsRetry: Yup
            .number()
            .required('Informe o tempo para reenvio caso ocorra algum erro'),
        setup: Yup
            .string()
            .required('Informe o setup'),
        typeBot: Yup.string().optional()
    });

    const formik = useFormik({
        initialValues: {
            id: 0,
            name: '',
            hourlyLimitSup: 20,
            hourlyLimitInf: 20,
            setup: 'megaapi',
            typeBot: 'hot',
            secondsRetry: 90,
        },
        validationSchema: BOT_FORM,
        onSubmit: ({ id, name, hourlyLimitSup, hourlyLimitInf, setup, secondsRetry, typeBot }) => {
            if (!bot) {
                dispatch(
                    createBot(
                        name,
                        hourlyLimitSup,
                        hourlyLimitInf,
                        secondsRetry,
                        setup,
                        typeBot,
                        (error, message) => {
                            onClose();

                            if (error) {
                                toast(
                                    message, 
                                    {
                                        autoClose: 1500,
                                        type: 'error',
                                    }
                                )
                                return;
                            } 

                            toast(
                                message, 
                                {
                                    autoClose: 1500,
                                    type: 'success',
                                }
                            );

                            return;
                        }
                    )
                );
                return;
            }

            dispatch(
                updateBot(
                    id,
                    name,
                    hourlyLimitSup,
                    hourlyLimitInf,
                    secondsRetry,
                    setup,
                    typeBot,
                    (err, msg) => {
                        onClose();
                        if (err) {
                            toast(
                                msg, 
                                {
                                    autoClose: 1500,
                                    type: 'error',
                                }
                            )
                            return;
                        } 

                        toast(
                            msg, 
                            {
                                autoClose: 1500,
                                type: 'success',
                            }
                        );
                        return;
                    }
                )
            );
            return;
        }
    });

    const { 
        errors,
        touched,
        values,
        handleSubmit,
        getFieldProps,
    } = formik;

    const onClose = () => {
        dispatch(toggleModal('form', false));
        formik.resetForm();
        dispatch(
            setValue({
                bot: null,
            }),
        );
    }

    const handleGetCategories = async () => {
        try {
            setLoadingCategories(true);

            const result = await api.get('/Categories', {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
            });

            setCategories(result.data?.data);
        } catch (e: any) {
            console.log('Error get categories:', e);
        }

        setLoadingCategories(false);
    }

    useEffect(() => {
        handleGetCategories();
        dispatch(setValue({ bot: null }));
        dispatch(toggleModal('form', false));
    }, []);

    useEffect(() => {
        formik.resetForm();
        dispatch(
            setValue({
                loading: {
                    update: false,
                    create: false,
                    index: false,
                    connect: false,
                    logout: false,
                }
            })
        )
    }, []);

    useEffect(() => {
        if (bot !== null) {
            formik.setValues({
                id: bot?.id,
                name: bot?.name,
                hourlyLimitSup: bot?.hourlyLimitSup || bot?.hourlyLimit,
                hourlyLimitInf: bot?.hourlyLimitInf || bot?.hourlyLimit,
                secondsRetry: bot?.secondsRetry,
                setup: bot?.args?.setup,
                typeBot: bot?.args?.typeBot,
            });
        }
    }, [modal?.form]);

    return (
        <Dialog
            open={modal?.form}
            onClose={onClose}
        >
            <DialogTitle>
                {bot ? 'Alterar bot' : 'Cadastrar um novo bot'}
            </DialogTitle>

            <DialogContent style={{ minWidth: IS_FULL_SCREEN ? '100%' : 500 }}>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Stack spacing={3} mb={2}>
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                type="text"
                                label="Nome do bot"
                                {...getFieldProps('name')}
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                            />
                            <TextField
                                fullWidth
                                type="number"
                                label="Limite superior de envio por hora"
                                {...getFieldProps('hourlyLimitSup')}
                                error={Boolean(touched.hourlyLimitSup && errors.hourlyLimitSup)}
                                helperText={touched.hourlyLimitSup && errors.hourlyLimitSup}
                            />
                            <TextField
                                fullWidth
                                type="number"
                                label="Limite inferior de envio por hora"
                                {...getFieldProps('hourlyLimitInf')}
                                error={Boolean(touched.hourlyLimitInf && errors.hourlyLimitInf)}
                                helperText={touched.hourlyLimitInf && errors.hourlyLimitInf}
                            />
                            <TextField
                                fullWidth
                                type="number"
                                label="Tempo para reenvio caso ocorra algum erro (em segundos)"
                                {...getFieldProps('secondsRetry')}
                                error={Boolean(touched.secondsRetry && errors.secondsRetry)}
                                helperText={touched.secondsRetry && errors.secondsRetry}
                            />
                            <FormControl fullWidth size="medium" required>
                                <InputLabel>Setup</InputLabel>
                                <Select
                                    {...getFieldProps('setup')}
                                    label="Setup"
                                    error={Boolean(touched.setup && errors.setup)}
                                    // helperText={touched.hourlyLimitInf && errors.hourlyLimitInf}
                                    // {...getFieldProps('setup')}
                                >
                                    <MenuItem key={'megaapi'} value={'megaapi'}>MegaApi</MenuItem>
                                    <MenuItem value={'z-api'}>Z-Api</MenuItem>
                                    <MenuItem value={'zapfy'}>ZapFy</MenuItem>
                                    <MenuItem value={'hotapi'}>HotApi</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth size="medium" required>
                                <InputLabel>Destinado à leads:</InputLabel>
                                <Select
                                    {...getFieldProps('typeBot')}
                                    label="Destinado à leads"
                                    error={Boolean(touched.typeBot && errors.typeBot)}
                                    // helperText={touched.hourlyLimitInf && errors.hourlyLimitInf}
                                    // {...getFieldProps('setup')}
                                >
                                    {categories.map((category: any) => (
                                        <MenuItem key={category.slug} value={category.slug}>{category.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                           
                            <LoadingButton
                                fullWidth
                                type='submit'
                                variant='contained'
                                loading={loading?.create || loading?.update}
                            >
                                {bot ? 'Alterar' : 'Criar'}
                            </LoadingButton>
                        </Stack>
                    </Form>
                </FormikProvider>
            </DialogContent>
        </Dialog>
    )
}

export default memo(BotForm);